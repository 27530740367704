import React, { useEffect } from "react"
import footerStyles from "./footer.module.css"

export default () => {
  
  useEffect(() => {
    let e = document.getElementById("footer_copyright_year")
    let year = new Date()
    e.innerText = year.getFullYear()
  }, [])

  return (
    <div className={footerStyles.footer}>
      <p>&copy; James Bale <span id="footer_copyright_year"></span></p>
    </div>
  )
}