import React from "react"
import contactStyles from "./contact.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

function ContactMethod(props) {
    return <h2><FontAwesomeIcon icon={props.icon} /><a target="_blank" rel="noopener noreferrer" className={contactStyles.contactLink} href={props.link}>  {props.text}</a></h2>       
}

export default () => (
    <div className={contactStyles.container}>
        <a className="anchor" href="https://www.jamesbale.dev/#contact" id="contact">Contact</a>
        <h1 className={contactStyles.title}>Contact</h1>
        <h2 className={contactStyles.splash}>I am available for contract development work, please do get in touch to discuss how my skills align with your needs.</h2>
        {/* <h1 className={contactStyles.how}>How?</h1> */}
        <ContactMethod icon={faEnvelope} link="mailto:james@jamesbale.dev" text="Send me an email" />
        {/* <ContactMethod icon={faGithub} link="https://www.github.com/DiNitride/" text="Github" />  */}
    </div>
)
